<template>
  <section>
    <section class="margin-top-2-rem margin-left-2-rem margin-right-2-rem">
      <progress
        class="progress is-large is-info"
        max="100"
      >
        Logging out
      </progress>
    </section>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

export default {
  name: 'Logout',
  async created() {
    await this.logout();

    for (const dialog of Vue.prototype.dialogs) {
      dialog.close();
    }
    setTimeout(this.forwardToLogin, 1000);
  },
  methods: {
    forwardToLogin() {
      this.$router.push({name: 'Login'})
    },
    ...mapActions('user', ['logout']),
  },
};
</script>

<style scoped>

</style>
